<template>
    <div>
        <router-link to="/containers" class="text-secondary">
            <i class="fas fa-long-arrow-alt-left" />
            Back
        </router-link>

        <page-header :title="title" :items="items" />

        <div v-if="container.id" class="row">
            <div class="col-xl-9 mb-3">
                <div class="card">
                    <div class="card-body">
                        <show-form v-model="container" />
                    </div>
                </div>
            </div>

            <div class="col-xl-3 mb-3">
                <div class="card">
                    <div class="card-body">
                        <item-information
                            :sections="sections"
                            :item="container"
                            @on-delete-confirm="onDeleteConfirm"
                        />
                    </div>
                </div>
            </div>
        </div>
        <skeleton-loader v-else />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';
import { StatusCodes as HTTP } from 'http-status-codes';

import ShowForm from '@/components/containers/ShowForm';
import ItemInformation from '@/components/elements/ItemInformation';

export default {
    page: {
        title: 'Manage Container'
    },

    components: {
        ShowForm,
        ItemInformation
    },

    data() {
        const {
            params: { id = null }
        } = this.$route;

        return {
            title: id ? 'Show container' : 'Track container',
            items: [
                {
                    text: 'Container Tracking',
                    to: '/'
                },
                {
                    text: 'Containers',
                    to: '/containers'
                },
                {
                    text: id ? 'Show' : 'Track',
                    active: true
                }
            ],
            id,
            container: {},
            sections: {
                status: true
            }
        };
    },

    async created() {
        try {
            this.container = await this.getContainer(this.id);
        } catch (error) {
            this.$toasterError();

            console.error(error);

            Sentry.captureException(error);
        }
    },

    methods: {
        ...mapActions({
            getContainer: 'containers/show',
            untrackContainer: 'containers/untrack'
        }),

        async onDeleteConfirm() {
            try {
                await this.untrackContainer(this.id);

                this.$toaster('Item has been deleted');

                this.$router.push('/containers');
            } catch (error) {
                const { response } = error;

                if (response) {
                    const { data, status } = response;

                    if (status === HTTP.UNPROCESSABLE_ENTITY && data) {
                        this.$toasterError(data);

                        return;
                    }
                }

                console.error(error);

                Sentry.captureException(error);

                this.$toasterError();
            }
        }
    }
};
</script>
