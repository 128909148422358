<template>
    <div class="row">
        <div class="col-12 form-group">
            <label for="number">Container ID</label>
            <input
                id="number"
                :value="value.number"
                class="form-control"
                type="text"
                disabled
                placeholder="XXXU0000000"
            />
        </div>

        <div class="col-12 form-group">
            <label for="supplierStatus">Supplier Status</label>
            <input
                id="supplierStatus"
                :value="value.supplierStatus"
                class="form-control"
                type="text"
                disabled
            />
        </div>

        <div class="col-12 form-group">
            <label for="finalPod">Final Pod</label>
            <input
                id="finalPod"
                :value="value.finalPod"
                class="form-control"
                type="text"
                disabled
            />
        </div>

        <div class="col-12 form-group">
            <label for="finalPodEta">Final Pod Eta</label>
            <input
                id="finalPodEta"
                :value="value.finalPodEta | dateOnly"
                class="form-control"
                type="text"
                disabled
            />
        </div>

        <div class="col-12 form-group">
            <label for="daysInStatus">Days In Status</label>
            <input
                id="daysInStatus"
                :value="daysInStatus"
                class="form-control"
                type="number"
                disabled
            />
        </div>

        <div class="col-12 form-group">
            <label for="transitTime">Transit Time</label>
            <input
                id="transitTime"
                :value="value.transitTime"
                class="form-control"
                type="number"
                disabled
            />
        </div>

        <div class="col-12 form-group">
            <label>Image Path</label>
            <div>
                <a :href="value.imagePath">
                    <i class="fas fa-image"></i>
                </a>
            </div>
        </div>

        <div class="col-12 form-group">
            <label for="hasArrived">Has Arrived</label>
            <input
                id="hasArrived"
                :value="value.hasArrived ? 'Yes' : 'No'"
                class="form-control"
                type="text"
                disabled
            />
        </div>

        <div class="col-12 form-group">
            <label for="departFromPort">Departed From Port</label>
            <input
                id="departFromPort"
                :value="value.departFromPort ? 'Yes' : 'No'"
                class="form-control"
                type="text"
                disabled
            />
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
    props: {
        value: {
            type: Object,
            required: false,
            default: () => {}
        }
    },

    computed: {
        daysInStatus() {
            const startDate = dayjs(this.value.statusChangedAt);

            return dayjs().diff(startDate, 'day');
        }
    }
};
</script>
