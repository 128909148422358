<template>
    <div class="card section">
        <div class="card-body">
            <div class="row">
                <h5 class="col-12 text-uppercase bg-light text-center py-1">
                    {{ title }}
                </h5>
                <div class="col-12">
                    <v-select
                        :value="value"
                        :label="label"
                        :multiple="multiple"
                        :options="searchResults"
                        @input="onInput"
                        @search="onSearch"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as Sentry from '@sentry/vue';

export default {
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: [Array, Object],
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: 'name'
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        },
        getFunctionName: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            searchResults: []
        };
    },

    methods: {
        async onSearch(val) {
            if (!val) {
                this.searchResults = [];

                return;
            }

            try {
                const { rows } = await this.$store.dispatch(
                    this.getFunctionName,
                    {
                        perPage: 5,
                        page: 1,
                        search: val
                    }
                );

                this.searchResults = rows;
            } catch (err) {
                console.error(err);

                Sentry.captureException(err);

                this.$toasterError();
            }
        },

        onInput(val) {
            this.$emit('input', val);
        }
    }
};
</script>
