<template>
    <div class="item-information">
        <div v-if="sections.status" class="card section">
            <div class="card-body">
                <div class="row">
                    <h5 class="col-12 text-uppercase bg-light text-center py-1">
                        Status
                    </h5>
                    <div class="col-12">
                        <div class="d-flex flex-column mb-3">
                            <div
                                v-if="item.createdAt"
                                class="d-flex flex-wrap my-2"
                            >
                                <div class="mr-1">
                                    <b>Created at: </b>
                                </div>
                                <div>
                                    {{ item.createdAt | formatDate }}
                                </div>
                            </div>
                            <div
                                v-if="item.updatedAt"
                                class="d-flex flex-wrap my-2"
                            >
                                <div class="mr-1">
                                    <b>Last update at:</b>
                                </div>
                                <div>
                                    {{ item.updatedAt | formatDate }}
                                </div>
                            </div>
                            <div
                                v-if="item.author"
                                class="d-flex flex-wrap my-2"
                            >
                                <div class="mr-1">
                                    <b>Created by:</b>
                                </div>
                                {{ item.author.fullName }}
                                <router-link
                                    v-if="item.author.id"
                                    :to="`/users/${item.author.id}/edit`"
                                    class="ml-2"
                                >
                                    <i class="fas fa-external-link-alt" />
                                </router-link>
                            </div>
                        </div>
                        <div
                            v-if="componentOptions.deleteButton"
                            class="col-12 d-flex justify-content-center"
                        >
                            <button
                                v-b-modal.confirm-delete-modal
                                type="button"
                                class="btn btn-danger btn-static waves-effect waves-light mb-4"
                            >
                                <i class="far fa-trash-alt" />
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="sections.customAuthor" class="card section">
            <div class="card-body">
                <div class="row">
                    <h5 class="col-12 text-uppercase bg-light text-center py-1">
                        Custom author name
                    </h5>
                    <div class="form-group col-12">
                        <div class="input-group">
                            <input
                                :value="customAuthorName"
                                type="text"
                                class="form-control"
                                placeholder="Custom author name"
                                @blur="onCustomAuthorNameBlur"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="sections.permalink" class="card section">
            <div class="card-body">
                <div class="row">
                    <h5 class="col-12 text-uppercase bg-light text-center py-1">
                        Permalink
                    </h5>
                    <div class="form-group col-12">
                        <label for="slug">URL Slug</label>
                        <div class="input-group">
                            <input
                                id="slug"
                                v-model="newSlug"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': slugError
                                }"
                                placeholder="URL slug"
                                @blur="onSlugBlur"
                            />
                            <div class="invalid-feedback">
                                {{ slugError }}
                            </div>
                        </div>
                        <div class="mt-2">
                            {{ slugUrl }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="sections.featuredImage" class="card section">
            <div class="card-body">
                <div class="row">
                    <h5 class="col-12 text-uppercase bg-light text-center py-1">
                        Featured Image
                    </h5>
                    <div class="col-12">
                        <div class="form-group">
                            <media-chooser
                                v-model="image"
                                type="image"
                                id-only
                            />
                        </div>
                    </div>
                    <div v-if="sections.imageAuthor" class="col-12">
                        <div class="form-group">
                            <label for="imageAuthor">Image Author</label>
                            <input
                                :value="imageAuthor"
                                type="text"
                                class="form-control"
                                placeholder="Image author name"
                                @blur="onImageAuthorBlur"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <select-section
            v-if="sections.category"
            :value="category"
            title="Category"
            :get-function-name="getCategoriesFunctionName"
            @input="onCategoryChange"
        />

        <select-section
            v-if="sections.campuses"
            :value="campuses"
            title="Campuses"
            multiple
            get-function-name="campuses/index"
            @input="onCampusesChange"
        />

        <select-section
            v-if="sections.tags"
            :value="tags"
            title="Tags"
            multiple
            :get-function-name="getTagsFunctionName"
            @input="onTagsChange"
        />

        <div v-if="sections.meta" class="card section">
            <div class="card-body">
                <div class="row">
                    <h5 class="col-12 text-uppercase bg-light text-center py-1">
                        Meta
                    </h5>
                    <div class="form-group col-12">
                        <label for="pageTitle">Page title</label>
                        <div class="input-group">
                            <input
                                id="pageTitle"
                                v-model="metaCopy.title"
                                type="text"
                                class="form-control"
                                dir="auto"
                                placeholder="Page title"
                            />
                        </div>
                    </div>
                    <div class="form-group col-12">
                        <label for="pageDescription">Page description</label>
                        <div class="input-group">
                            <textarea
                                id="pageDescription"
                                v-model="metaCopy.description"
                                type="text"
                                class="form-control"
                                dir="auto"
                                rows="5"
                                placeholder="Page description"
                            />
                        </div>
                    </div>
                    <div class="form-group col-12">
                        <label for="canonicalUrl">Canonical URL</label>
                        <div class="input-group">
                            <input
                                id="canonicalUrl"
                                v-model="metaCopy.canonicalUrl"
                                type="text"
                                class="form-control"
                                dir="auto"
                                placeholder="Canonical URL"
                            />
                        </div>
                    </div>
                    <div class="form-group col-12">
                        <b-form-checkbox v-model="metaCopy.noFollow" switch>
                            No Follow
                        </b-form-checkbox>
                    </div>
                    <div class="form-group col-12">
                        <b-form-checkbox v-model="metaCopy.noIndex" switch>
                            No Index
                        </b-form-checkbox>
                    </div>
                    <div class="form-group col-12">
                        <label for="ogTitle">Open Graph title</label>
                        <div class="input-group">
                            <input
                                id="ogTitle"
                                v-model="metaCopy.ogTitle"
                                type="text"
                                class="form-control"
                                dir="auto"
                                placeholder="Open Graph title"
                            />
                        </div>
                    </div>
                    <div class="form-group col-12">
                        <label for="ogDescription">
                            Open Graph description
                        </label>
                        <div class="input-group">
                            <textarea
                                id="ogDescription"
                                v-model="metaCopy.ogDescription"
                                type="text"
                                class="form-control"
                                dir="auto"
                                rows="5"
                                placeholder="Open Graph description"
                            />
                        </div>
                    </div>
                    <div class="form-group col-12">
                        <label for="ogImage">Open Graph image</label>
                        <media-chooser
                            v-model="metaCopy.ogImage"
                            type="image"
                        />
                    </div>
                </div>
            </div>
        </div>
        <confirmation-modal
            id="confirm-delete-modal"
            :title="'Do you want to delete this item?'"
            @agree="$emit('on-delete-confirm')"
        />
    </div>
</template>

<script>
import slugify from 'slugify';
import { frontendUrl } from '@/config';
import SelectSection from './SelectSection';
import ConfirmationModal from '@/components/elements/ConfirmationModal';

export default {
    components: {
        SelectSection,
        ConfirmationModal
    },

    props: {
        sections: {
            type: Object,
            required: false,
            default: () => ({
                status: false,
                permalink: false,
                featuredImage: false,
                imageAuthor: false,
                category: false,
                meta: false,
                customAuthor: false,
                campuses: false
            })
        },
        slug: {
            type: String,
            required: false,
            default: ''
        },
        slugError: {
            type: String,
            required: false,
            default: ''
        },
        item: {
            type: Object,
            required: false,
            default: () => ({})
        },
        featuredImage: {
            type: String,
            required: false,
            default: ''
        },
        editMode: {
            type: Boolean,
            required: false,
            default: false
        },
        options: {
            type: Object,
            required: false,
            default: () => ({})
        },
        getCategoriesFunctionName: {
            type: String,
            required: false,
            default: ''
        },
        category: {
            type: Object,
            required: false,
            default: null
        },
        getTagsFunctionName: {
            type: String,
            required: false,
            default: ''
        },
        tags: {
            type: Array,
            required: false,
            default: () => []
        },
        campuses: {
            type: Array,
            required: false,
            default: () => []
        },
        customAuthorName: {
            type: String,
            required: false,
            default: ''
        },
        imageAuthor: {
            type: String,
            required: false,
            default: ''
        }
    },

    data() {
        return {
            newSlug: '',
            image: '',
            defaultOptions: {
                featuredImagePath: '',
                deleteButton: true,
                slugBasicUrl: ''
            },
            metaCopy: {}
        };
    },

    computed: {
        slugUrl() {
            const { slugBasicUrl } = this.componentOptions;

            if (!slugBasicUrl) {
                return `${frontendUrl}/${this.newSlug || '_______'}`;
            }

            return `${frontendUrl}/${slugBasicUrl}/${this.newSlug ||
                '_______'}`;
        },
        componentOptions() {
            return { ...this.defaultOptions, ...this.options };
        }
    },

    watch: {
        image() {
            this.$emit('on-featured-image-change', this.image);
        },
        slug: {
            handler() {
                this.newSlug = this.slug;

                this.onSlugBlur();
            },
            immediate: true
        },
        metaCopy: {
            handler() {
                this.$emit('meta-change', this.metaCopy);
            },
            deep: true
        }
    },

    async created() {
        if (this.featuredImage) {
            this.image = this.featuredImage;
        }

        if (typeof this.item.meta === 'object') {
            this.metaCopy = { ...this.item.meta };
        }
    },

    methods: {
        onSlugBlur() {
            if (this.newSlug) {
                this.newSlug = this.newSlug.toLowerCase();
            }

            this.newSlug = slugify(this.newSlug, {
                lower: true,
                strict: true
            });
            this.$emit('on-slug-change', this.newSlug);
        },

        onTagsChange(tags) {
            this.$emit('on-tags-change', tags);
        },

        onCampusesChange(campuses) {
            this.$emit('on-campuses-change', campuses);
        },

        onCategoryChange(category) {
            this.$emit('on-category-change', category);
        },

        onCustomAuthorNameBlur($e) {
            this.$emit('custom-author-name-change', $e.target.value);
        },

        onImageAuthorBlur($e) {
            this.$emit('on-image-author-change', $e.target.value);
        }
    }
};
</script>
